import { Route, Routes} from 'react-router-dom';
import './App.css';
import Tickets from './components/Tickets';
import WelcomePage from './components/WelcomePage';
import React, { Fragment } from "react";
import ChatWindow from './components/LiveChat/ChatWindow';
import { UnityClient } from './components/UnityClient';
import {
  StompSessionProvider,
} from "react-stomp-hooks";
import { AvatarPage } from './components/AvatarPage/AvatarPage';
import LoadingPage from './components/LoadingPage/LoadingPage';
import ReadyPlayerMeCreator from './components/ReadyPlayerMe/ReadyPlayerMeCreator';
import { Landing3D } from './components/LoadingPage/Landing3D';
import { InboundLinkRouting } from './components/InboundLinkRouting';

const App: React.FC = () => {

  return (
      <Fragment>
          
                <Routes> 
                    <Route
                      path="/"
                      element={<Fragment>
                          <Landing3D/>  
                        </Fragment>}
                    />
                    <Route
                      path="/studio/:id"
                      element={<InboundLinkRouting />}
                    />
                    <Route
                      path="/studio"
                      element={<InboundLinkRouting />}
                    />
                    <Route
                      path="/chat/:name"
                      element={<StompSessionProvider
                        url={"wss://gennius-roomsvr.vegascity.live/stomp"}><ChatWindow/>
                      </StompSessionProvider>}
                    />
                    <Route 
                      path="/editor"
                      element={<ReadyPlayerMeCreator />}
                    />
                </Routes>
        </Fragment>
  );
}

export default App;
