import {
    useSubscription,
  } from "react-stomp-hooks";
import { useSelector, useDispatch } from 'react-redux';
import { setMessageHistory } from "../../store/messages";
import ChatBubble, { msg } from "./chatWindowComponents/ChatBubble";
import ChatInput from "./ChatInput";
import { setAvatarName, setChatHandle } from "../../store/appUser";
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from "react";
import { setEmail, setName } from "../../store/appUser";
import { useAppSelector } from "../../store/hooks";

type newMessages = {
    msg: msg,
    guid: string
}

const ChatWindow: React.FC = (props) => {

    let params = useParams()
    var location = window.location;
    var baseUrl = location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "");
    const { messageHistory } = useAppSelector((state) => state.messages)
    const {userMessages, name} = useAppSelector((state) => state.appUser)
    const [hide, setHide] = useState(false)
    const dispatch = useDispatch()
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const messageRef = useRef(messageHistory)

    const scrollToBottom = () => {
      const chatContainer = chatContainerRef.current;
      if(chatContainer){
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    };

    useEffect(() => {
        scrollToBottom()
    }, [messageHistory])
    
    useEffect(() => {
        setInterval(() => {
            const iframe = document.querySelector('iframe[name="Botmaker"]') as HTMLIFrameElement | null;;
            if (iframe) {
                iframe.style.display  = 'none'
                setHide(true)
            }
        },10)
        
    }, [hide])

    useEffect(() => {
        if(params.name){
            dispatch(setAvatarName(params.name))
        }
        const iframe = document.querySelector('iframe[name="Botmaker"]') as HTMLIFrameElement | null;;
            if (iframe) {
                iframe.style.display  = 'none'
                setHide(true)
            }
    },[params])

    // listen for Broadcasts on the chat topic
    useSubscription("/topic/chat", (message) => {
        formatMessages(message.body) 
    })
    useEffect(() => {
        messageRef.current = messageHistory
    },[messageHistory])

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            if (event.origin !== baseUrl) return;
            const message = event.data;
            if (message === 'Chat Opened' ) {
                scrollToBottom()
            }
            
        };
  
        window.addEventListener('message', handleMessage);
  
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [baseUrl]);

    // add the incoming messages to the message history state array and attach timestamp, only store up to 500 messages
    function formatMessages(message: string){
        let data = JSON.parse(message)
        let currentMessages: Array<any> = [...messageRef.current]

        if(currentMessages.length > 500){
            
            currentMessages.shift()
            currentMessages.push({from: data.from, msg: data.msg ? data.msg : data.body, guid: data.guid ? data.guid : "1234", timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })})
            dispatch(setMessageHistory(currentMessages))

        } else {
            currentMessages.push({from: data.from, msg: data.msg ? data.msg : data.body, guid: data.guid ? data.guid : "1234", timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })})
            dispatch(setMessageHistory(currentMessages))
        }
        sendMessageToParent()
    }


    // display the latest message at the top
    function reverseHistory(){
        let currentMessages = [...messageHistory]
        return currentMessages.reverse()
    }

    // check the stored user sent messages so that styling can be applied to the chat window
    function checkUserMessages(msgGuid: string) {
        for (const usrMsg of userMessages) {
            if (usrMsg.guid === msgGuid) {
                return true;
            }
        }
        return false;
    }
    
    function sendMessageToParent() {
        window.parent.postMessage("New Message Recieved", baseUrl);
    }

    return(
        <div>
            <div className="chat-container" ref={chatContainerRef}>
                <ul>
                    {
                        messageHistory.map((msg, index) => (
                            
                            checkUserMessages(msg.guid) ? 
                            <ChatBubble key={index + "bubble"} index={index} className="user-chat-bubble" msg={msg} timeClassName="user-bubble-timestamp"/>
                            :
                            <ChatBubble key={index + "bubble"} index={index} className="chat-bubble" msg={msg} timeClassName="bubble-timestamp"/>
                        ))
                    }
                </ul>
            </div>
            <ChatInput />
        </div>
    )
}

export default ChatWindow;
