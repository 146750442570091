import React, { useState, useEffect } from "react";
import CustomButton from "./CustomButton";
import { AvatarDisplay } from "./AvatarDisplay";
import { useDispatch } from "react-redux";
import { setAvatarGlb, setAvatarName } from "../../store/appUser";
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from "../../store/hooks";
import { cleanMessage } from "../LiveChat/chatWindowComponents/ChatBubble";

interface WindowSize {
    width: number,
    height: number
}

type Props = {
    redirect: Function
}

export const AvatarPage: React.FC<Props> = ({redirect}) => {

    const history = useNavigate();
    let dispatch = useDispatch();
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: window.innerWidth,
        height: window.innerHeight
      });
    const [pickOpacity, setPickOpacity] = useState<number>(1)
    const [buildOpacity, setBuildOpacity] = useState<number>(0.2)
    const [background, setBackground] = useState<string>("none")
    const [hasCookie, setHasCookie] = useState<boolean>(false)
    const { avatarName} = useAppSelector((state) => state.appUser)
    const circleScalingFactor = Math.min(windowSize.width / 1800, windowSize.height / 1800);
  

    useEffect(() => {
        let cookie = Cookies.get("avatarUrl")
        if(cookie){
            dispatch(setAvatarGlb(cookie))
            handleShowAvatar(cookie)
            setHasCookie(true)
        } 
    },[hasCookie])
    
    useEffect(() => {
        let username = Cookies.get("username");
        if(username){
            dispatch(setAvatarName(username))
        }
    },[])

    useEffect(() => {
        const handleResize = () => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
          });
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    function handleMouseEnter(index: number){
        if(index === 0){
            setPickOpacity(0.2)
            setBuildOpacity(1)
        } else {
            setPickOpacity(1)
            setBuildOpacity(0.2)
        }
    }

    function handleShowAvatar(glb: string){
        setBackground("#61dafb")
        dispatch(setAvatarGlb(glb))
        const logoImage = document.getElementById("logoImage") as HTMLElement | null;
            if (logoImage) {
            logoImage.style.display = "none";
        }
    }

    function handleAvatarName(name:string){
        let clean = cleanMessage(name)
        dispatch(setAvatarName(clean))
        Cookies.set("username", clean)
    }

    return (
        <div className="avatar-background">
            <div className="dotted dot1 offset" style={{height: 1700 * circleScalingFactor, width:1700 * circleScalingFactor}}>
            </div>
            <div className="circle  offset" style={{height: 1200 * circleScalingFactor, width:1200 * circleScalingFactor, backgroundColor: background}}>
                <div 
                    className="avatar-square1" 
                    onMouseEnter={() => handleMouseEnter(0)} 
                    style={{background: pickOpacity === 1 ?"#61dafb" : "rgb(10,64,242)", boxShadow:pickOpacity === 1 ?" 0px 0px 50px 10px #61dafb" : " 0px 0px 50px 10px rgb(10,64,242)" }}
                ></div>
                <div 
                    className="avatar-square2" 
                    onMouseEnter={() => handleMouseEnter(1)}
                    style={{background: buildOpacity === 1 ?"#61dafb" : "rgb(10,64,242)", boxShadow:buildOpacity === 1 ?" 0px 0px 50px 10px #61dafb" : " 0px 0px 50px 10px rgb(10,64,242)" }}
                ></div>
            <img id="logoImage" src={"./images/worldAsOne.png"} alt="no image" className="avatar-logo" height={500*circleScalingFactor} width={500*circleScalingFactor}/>
            </div>
            <div className="avatar-input-area2"  onMouseEnter={() => handleMouseEnter(1)}>
                <h3>Enter your name</h3>
                <div>
                    <input 
                        className="custom-input" 
                        placeholder="Name" 
                        value={avatarName} 
                        onChange={e =>{ 
                           handleAvatarName(e.target.value)
                        }}></input>
                </div>
                <div style={{width: '50%'}}>
                    <input
                        type='image'
                        src={avatarName.length > 0 ? "images/buttons/join_button_avatarscreen_Active.png" : "images/buttons/join_button_avatarscreen_Inactive.png"}
                        disabled={avatarName.length > 0 ? false : true}
                        className='customButton'
                        onClick={() => redirect(true)}
                    >
                        
        </input>
                </div>
            </div>
            <div className="avatar-input-area1" onMouseEnter={() => handleMouseEnter(0)} >
                <h3>Choose your Avatar</h3>
                <p style={{fontSize: '65%'}}>Use a predefined Avatar: </p>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <CustomButton 
                        inactive={"images/buttons/avatar_type_A_button_avatarscreen_Inactive.png"}
                        hover={"images/buttons/avatar_type_A_button_avatarscreen_Hover.png"}
                        pressed={"images/buttons/avatar_type_A_button_avatarscreen_Pressed.png"}
                        click={() => handleShowAvatar("https://models.readyplayer.me/6641ec2fd5d11dcc57df141d.glb")}
                    />
                    <CustomButton 
                        inactive={"images/buttons/avatar_type_B_button_avatarscreen_Inactive.png"}
                        hover={"images/buttons/avatar_type_B_button_avatarscreen_Hover.png"}
                        pressed={"images/buttons/avatar_type_B_button_avatarscreen_Pressed.png"}
                        click={() => handleShowAvatar("https://models.readyplayer.me/6641ec98e6d7b4328f134d69.glb")}
                    />
            </div>
            <div style={{ marginTop: '5%', width: '100%' }}>
            <p style={{fontSize: '65%'}}>Build your own: </p>
                <CustomButton 
                    inactive={hasCookie ? "images/buttons/customisingInactive.png" :  "images/buttons/startCustomisingInactive.png"}
                    hover={hasCookie ? "images/buttons/customisingHover.png" :  "images/buttons/startCustomisingHover.png"}
                    pressed={hasCookie ? "images/buttons/customisingPressed.png" :  "images/buttons/startCustomisingPressed.png"}
                    click={() => history("/editor")}
                />
            </div>

                
            </div>
            <AvatarDisplay />
        </div>
    )
}