import { PulsingDiv } from "./PulsingDiv"
import CirclesAnim from "./CirclesAnim"
import React from "react"

const LoadingPage: React.FC = () =>{

    return(
        <div style={{height: '100vh', width: '100wh'}}>
            <CirclesAnim colors={[1,2,3,4,5,6,7,8,9,10]} interval={500}/>
            <div className="centre pulsing">
                <PulsingDiv />
            </div>
        </div>
    )
}

export default React.memo(LoadingPage);