import { useEffect, useState } from "react"
import { RouteParams, UnityClient } from "./UnityClient";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { AvatarPage } from "./AvatarPage/AvatarPage";

export const InboundLinkRouting: React.FC = () => {

    const {id} = useParams<RouteParams>();
    const [hasCookie, setHasCookie] = useState(true);
    const bypassId = "2914dced-44ad-4ed4-8d69-1e82af4e09bc";
    
       useEffect(() => {
        let cookie = Cookies.get("avatarUrl");
        let username = Cookies.get("username");

        if (cookie === undefined || username === undefined) {
            setHasCookie(false);
        }
    }, []);

    if (id === bypassId) {
        return <UnityClient id={id} />;
    }

    return(
        <>
            {
                hasCookie ? <UnityClient id={id}/> : <AvatarPage redirect={setHasCookie}/>
            }
        </>
    )
}