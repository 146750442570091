import { createSlice} from "@reduxjs/toolkit";

interface userMessage {
    guid: string,
    mesg: string, 
    timestamp: string
}

interface AppUserState {
    name: string,
    email: string,
    chatHandle: string,
    userMessages: userMessage[],
    avatarName: string,
    avatarGlb: string
}

const initialState: AppUserState = {
    name: "Anon",
    email: "",
    chatHandle: "",
    userMessages: [],
    avatarName: "",
    avatarGlb: ""
}
export const appUserSlice = createSlice({
    name:"appUser",
    initialState,
    reducers: {
        setName: (state, action) => {
            state.name = action.payload
        },
        setEmail: (state, action) => {
            state.email = action.payload
        },
        setUserMessages: (state, action) => {
            state.userMessages = action.payload
        },
        setChatHandle: (state, action) => {
            state.chatHandle = action.payload
        },
        setAvatarName: (state, action) => {
            state.avatarName = action.payload
        }, 
        setAvatarGlb: (state, action) => {
            state.avatarGlb = action.payload
        }
    }
});

export const { setEmail, setName, setUserMessages, setChatHandle, setAvatarName,setAvatarGlb } = appUserSlice.actions;

export default appUserSlice.reducer;