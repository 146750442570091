import {
    AvatarCreator,
    AvatarCreatorConfig,
  } from "@readyplayerme/react-avatar-creator";
import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { useDispatch } from 'react-redux';
import { setAvatarGlb } from "../../store/appUser";
import { useNavigate } from 'react-router-dom';

  const config: AvatarCreatorConfig = {
    clearCache: true,
    bodyType: "fullbody",
    quickStart: false,
    language: "en",
  };
  
  const style = { width: "100vw", height: "100vh", border: "none", margin: 0, zIndex:4500000 };
  

  const ReadyPlayerMeCreator: React.FC = () => {
    
    const dispatch = useDispatch()
    const history = useNavigate()

    useEffect(() => {
      let avatarUrlCookie = Cookies.get("avatarUrl")
      console.log("Cookie: " + avatarUrlCookie)
    },[])

    const handleOnUserSet = (event: any) => {
      console.log(`User ID is: ${event.data.id}`);
    };
  
    const handleOnAvatarExported = (event: any) => {
      let url = event.data.url
      Cookies.set("avatarUrl", url);
      dispatch(setAvatarGlb(url))
      history("/studio")
    };
  
    const handleUserAuthorized = (event: any) => {
      console.log(`User is:`, event.data);
    };
  
    const handleAssetUnlocked = (event: any) => {
      console.log(`Asset unlocked is: ${event.data.assetId}`);
    };
    
    return (
      <div style={{width: "100vw", height: "100vh" }}>
        <AvatarCreator
          subdomain="unityclientpage.readyplayer.me/avatar?frameApi="
          config={config}
          style={style}
          onAvatarExported={handleOnAvatarExported}
          onUserAuthorized={handleUserAuthorized}
          onAssetUnlock={handleAssetUnlocked}
          onUserSet={handleOnUserSet}
        />
      </div>
    );
  }
  
  export default ReadyPlayerMeCreator;