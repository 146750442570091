import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../store/hooks';
import ChatIcon from '@mui/icons-material/Chat';
import Cookies from 'js-cookie';


const LiveChat: React.FC = () => {

    const { name } = useAppSelector((state) => state.appUser)
    const [show, setShowChat] = useState(false)
    const [connect, setConnect] = useState(false)
    const [showPending, setShowPending] = useState("none")
    var location = window.location;
    var baseUrl = location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "");
    const showRef = useRef(show);
    const iFrameRef = useRef<HTMLIFrameElement>(null)

    useEffect(() => {
        showRef.current = show;
    }, [show]);
    
    useEffect(() => {
        const handleKeyPress = (event: { key: string; }) => {
          if (event.key === 'Enter') {
            setShowChat(!showRef.current)
          }
        };
        window.addEventListener('keypress', handleKeyPress);
    
        return () => {
          window.removeEventListener('keypress', handleKeyPress);
        };
    }, []);

    useEffect(() => {
      const handleMessage = (event: MessageEvent) => {
          if (event.origin !== baseUrl) return;
          const message = event.data;
          if (message === 'New Message Recieved' && !showRef.current) {
            setShowPending("")
          }
          
      };

      window.addEventListener('message', handleMessage);

      return () => {
          window.removeEventListener('message', handleMessage);
      };
  }, [baseUrl]);

  function sendMessage(message: string) {
      if(iFrameRef?.current?.contentWindow){
          iFrameRef.current.contentWindow.postMessage(
              message,
              baseUrl
          );
      }
  };

    return(
        <div>
           {connect && ( <div className="chat" style={{ height: show ? '300px' : '0%', width: show ? '600px' : '0%', display: show ? "" : "none"}}>
                <iframe
                    src={baseUrl+ `/#/chat/` + Cookies.get("username")}
                    width="100%" 
                    height="100%" 
                    frameBorder="0"
                    title="chat"
                    ref={iFrameRef}
                ></iframe>
            
            </div>)}
            <div>
            <div style={{ display: 'inline-block',position: 'absolute',  bottom:'2%',  right: '4%' }}>
                <div
                  style={{
                    width: '15px',
                    height: '15px',
                    borderRadius: '50%',
                    backgroundColor: 'green',
                    position: 'absolute',
                    bottom: '50px',  
                    right: '-20px',   
                    display: showPending,
                    zIndex: 260000
                  }}
                ></div>
                <button
                  onClick={() => {
                    setConnect(true) 
                    setShowChat(!showRef.current)
                    setShowPending("none")
                    sendMessage("Chat Opened")
                  }}
                  className='unityBtn'
                  style={{ position: 'relative', zIndex: 1 }}
                >
                  <ChatIcon />
                </button>
              </div>
            </div>
        </div>
    )
}

export default LiveChat;