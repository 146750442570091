import TextField from '@mui/material/TextField';
import axios from "axios";
import { useDispatch } from 'react-redux';
import { setNewMessage } from '../../store/messages';
import { setAvatarName, setUserMessages } from '../../store/appUser';
import React, { useEffect, useState } from 'react';
import {v4 as uuidv4} from 'uuid';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { useAppSelector } from '../../store/hooks';
import Cookies from 'js-cookie';
import { Send } from '@mui/icons-material';



const ChatInput: React.FC = () => {

    const { newMessage } = useAppSelector((state) => state.messages)
    const { avatarName, userMessages} = useAppSelector((state) => state.appUser)
    const [showEmojis, setShowEmojis] = useState(false)


    const dispatch = useDispatch()

    // post the message to the server
    function sendMessage(message: string, guid: string){

        let data = {
            msg: message,
            guid: guid,
            from: avatarName
        }

        axios.post("https://gennius-roomsvr.vegascity.live/api/chat/global", {
            topic: "/topic/chat",
            message: JSON.stringify(data)
        })
    }

    useEffect(() => {
        let nameCookie = Cookies.get("username")
        if(nameCookie){
            dispatch(setAvatarName(nameCookie))
        }
    },[])


    // prepare the message guid and add the message to the user messages, this will have to change when we have usernames
    function send(){
        let guid = uuidv4()
        sendMessage(newMessage, guid)
        let messageHistory: Object[] = [...userMessages]
        messageHistory.push({msg: newMessage, guid: guid})
        dispatch(setUserMessages(messageHistory))
        dispatch(setNewMessage(""))
    }

    // listen for return to send message
    function handleKeyDown(event: any){
        if (event.key === 'Enter') {
            send()
            dispatch(setNewMessage(""));
        }
    }

    

    return (
       
        <div className='chat-input'>
            <input 
                onKeyDown={(e) => handleKeyDown(e)} 
                id="chat-input" 
                type="text"
                autoComplete="off" 
                value={newMessage} 
                onChange={(e) => {
                    console.log("Dispatching message: "+ e.target.value)
                    dispatch(setNewMessage(e.target.value))}}
            />
            <button 
            className='send-button'
            onClick={() => {
                send()
                dispatch(setNewMessage(""));
            }}><Send /></button> 
            {/* <button className="emoji-button" style={{marginLeft: 0}} onClick={(e) => { e.stopPropagation();setShowEmojis(!showEmojis)}}>
                {"\u{1F929}"}
            </button> */}
            {/* <div className='emoji-picker'>
            {
                showEmojis ? <Picker 
                                data={data} 
                                onEmojiSelect={(emoji: any) => addEmoji(emoji)} onClickOutside={() => setShowEmojis(!showEmojis)}
                                theme='light'
                                /> : null
            }
            </div> */}
            
        </div>
        
    )
}

export default ChatInput;