import React from "react";
import { badWords } from "./badwordlist";

export type msg = {
    msg: string,
    from: string,
    timestamp: string
}

type chatBubbleProps = {
    index: number,
    className: string,
    msg: msg,
    timeClassName: string
}
var Filter = require('bad-words'),
filter = new Filter();
badWords.forEach(word => {
    filter.addWords(word)
})

const isEmoji = (str:string) => {
    const regex = /[\p{Emoji}]/u;
    return regex.test(str);
}

const isValidMessage = (str:string) => {
    const regex = /[a-zA-Z]+/;
    return regex.test(str);
};

export const cleanMessage = (str: string) => {
    if (str != null && typeof str === 'string' && str.trim() !== '') {
        return isEmoji(str) || !isValidMessage(str) ? str : filter.clean(str);
    }
    return '';
};

const ChatBubble: React.FC<chatBubbleProps> = ({index,className,msg,timeClassName}) => {
    
    
    return(
        <div>
            <li className="chat-bubble">
            <span style={{color: className === 'user-chat-bubble' ? 'rgb(100,234,255)' : 'white'}}>{cleanMessage(msg.from.length > 0 ? msg.from : "Guest")}</span> <span style={{color: 'lightgray'}}>said:</span> {cleanMessage(msg.msg)} </li>
        </div>
    )
}

export default ChatBubble;