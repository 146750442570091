import { createSlice} from "@reduxjs/toolkit";

export interface Message  {
    from:string,
    guid: string,
    msg: string, 
    timestamp: string
}

interface MessageState {
    messageHistory: Message[],
    newMessage: string
}

const initialState: MessageState = {
    messageHistory: [],
    newMessage:""
}

export const messagesSlice = createSlice({
    name:"messages",
    initialState,
    reducers: {
        setMessageHistory: (state, action) => {
            state.messageHistory = action.payload
        },
        setNewMessage: (state, action) => {
            state.newMessage = action.payload
        }
    }
});

export const { setMessageHistory, setNewMessage } = messagesSlice.actions;

export default messagesSlice.reducer;