import { createSlice} from "@reduxjs/toolkit";

interface TicketPurchaseState {
    firstName: string,
    lastName: string,
    email: string
}
const initialState: TicketPurchaseState = {
    firstName: "",
    lastName: "",
    email: ""
}
export const ticketPurchaseSlice = createSlice({
    name:"ticketPurchase",
    initialState,
    reducers: {
        setFirstName: (state, action) => {
            state.firstName = action.payload
        },
        setLastName: (state, action) => {
            state.lastName = action.payload
        },
        setEmail: (state, action) => {
            state.email = action.payload
        }
    }
});

export const { setEmail, setFirstName, setLastName } = ticketPurchaseSlice.actions;

export default ticketPurchaseSlice.reducer;