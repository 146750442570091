import { useState, useEffect } from "react";


type Props = {
    isHost: boolean
}

export const PartiesUi: React.FC<Props> = ({isHost}) => {

    const [display, setDisplay] = useState(false)

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `${process.env.PUBLIC_URL}/parties-setup.js`;
        script.type = 'text/javascript';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        window.isHost = isHost
    })

    return (
        <div>
            { isHost && (<a
                href="#"
                id="btnStartCall"
                style={{
                    backgroundColor: 'rgba(34, 36, 48, 0.7)',
                    borderRadius: '8px',
                    color: 'rgb(100,234,255)',
                    left: '50%',
                    outline: 'none',
                    padding: '16px',
                    position: 'fixed',
                    textDecoration: 'none',
                    top: '16px',
                    transform: 'translate(-50%, 0)',
                    display: 'none'
                }}
            >
                Start Call
            </a>)}

            <span
                id="lblIncomingCallInfo"
                style={{
                    backgroundColor: 'rgba(34, 36, 48, 0.7)',
                    borderRadius: '10px',
                    left: '50%',
                    lineHeight: '1',
                    padding: '8px',
                    position: 'fixed',
                    top: '16px',
                    transform: 'translate(-50%, 0)',
                    color: 'rgb(100,234,255)',
                    border: '2px white dotted',
                    display: 'none'
                }}
            >
                Incoming call from [[display_name]]...
            </span>
            <a
                href="#"
                id="btnAcceptCall"
                style={{
                    backgroundColor: '#33cc77',
                    borderRadius: '8px',
                    color: '#fff',
                    left: 'calc(50% - 8px)',
                    outline: 'none',
                    padding: '16px',
                    position: 'fixed',
                    textDecoration: 'none',
                    top: '60px',
                    transform: 'translate(-100%, 0)',
                    display: 'none'
                }}
            >
                Accept Call
            </a>
            <a
                href="#"
                id="btnRejectCall"
                style={{
                    backgroundColor: '#cc3377',
                    borderRadius: '8px',
                    color: '#fff',
                    left: 'calc(50% + 8px)',
                    outline: 'none',
                    padding: '16px',
                    position: 'fixed',
                    textDecoration: 'none',
                    top: '60px',
                    display: 'none'
                }}
            >
                Reject Call
            </a>

            <div
                id="cntCallInfo"
                style={{
                    backgroundColor: 'rgba(34, 36, 48, 0.7)',
                    color: 'rgb(100,234,255)',
                    borderRadius: '10px',
                    left: '50%',
                    lineHeight: '1',
                    padding: '8px',
                    position: 'fixed',
                    top: '16px',
                    transform: 'translate(-50%, 0)',
                    border: '2px white dotted',
                    display: 'none'
                }}
            >
                <h1
                    style={{
                        fontSize: '150%',
                        lineHeight: '1',
                        margin: '0',
                        padding: '0',
                        textAlign: 'center',
                        color: 'rgb(100,234,255)',
                    display: 'none'
                    }}
                >
                    Active Call
                </h1>
                <table style={{ color: 'rgb(100,234,255)' }}>
                <thead>
                    <tr>
                        <th>{isHost ? "User" : "Users Connected"}</th>
                        {isHost && (
                            <>
                                <th>Connected</th>
                                <th>Joined</th>
                                <th>Audio</th>
                            </>
                        )}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>[[display_name]]</td>
                        {isHost && (
                            <>
                                <td>[[is_connected]]</td>
                                <td>[[is_joined]]</td>
                                <td>[[audio_status]]</td>
                            </>
                        )}
                    </tr>
                </tbody>
            </table>
                
            <div style={{ textAlign: 'center' }}>
            { isHost && ( <a
                        href="#"
                        id="btnEndCall"
                        style={{
                            backgroundColor: '#cc3377',
                            borderRadius: '8px',
                            color: '#fff',
                            display: 'inline-block',
                            left: 'calc(50% + 8px)',
                            margin: '8px 0 0',
                            outline: 'none',
                            padding: '16px',
                            textDecoration: 'none',
                            top: '60px'
                        }}
                    >
                        End Call
                    </a>)}
                </div>
            </div>
        </div>
    );
}