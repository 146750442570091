import { useSpring, animated } from '@react-spring/web';
import React from 'react';


export const PulsingDiv: React.FC = () => {

    const styles = useSpring({
        loop: true,
        to: [{ scale: 2 }, { scale: 1 }],
        from: { scale: 1 },
        config: { duration: 1000 }, 
      });
    
      return (
        <animated.div
          style={{
            width: 100,
            height: 100,
            backgroundColor: 'rgb(100,234,255)',
            borderRadius: '50%',
            boxShadow: '0px 0px 50px 10px #61dafb',
            ...styles, 
          }}
        />
    );
};