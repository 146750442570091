import { configureStore,  } from "@reduxjs/toolkit";
import appUserReducer from './appUser';
import ticketPurchaseReducer from "./ticketPurchase";
import messagesReducer from './messages';

export const store = configureStore({
    reducer: {
        appUser: appUserReducer,
        ticketPurchases: ticketPurchaseReducer,
        messages: messagesReducer
    },
    // middleware: (getDefaultMiddleware) =>
    //     getDefaultMiddleware({
    //         serializableCheck: {
    //             serialize: (action: { type: string; }) =>
    //                 action.type === "timeAndDate/setDate" ? action : undefined,
    //             deserialize: (action: { type: string; }) =>
    //                 action.type === "timeAndDate/setDate" ? action : undefined,
    //         },
    //     }),
});

export type RootState = ReturnType<typeof store.getState>
export type AppStore = typeof store
export type AppDispatch = typeof store.dispatch
