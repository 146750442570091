import React, { useEffect, useState } from 'react';

type Props = {
    inactive: string,
    hover: string,
    pressed: string,
    click: Function
}
const CustomButton: React.FC<Props> = ({inactive, hover, pressed, click}) => {
    const [image, setimage] = useState(inactive)
  
    function handleImageChange(index: number){
        switch (index) {
            case 0:
                setimage(hover)
                break;
            case 1:
                setimage(inactive)
                break;
            case 2:
                setimage(pressed)
        }

    }

    useEffect(() => {
        setimage(inactive)
    },[inactive])

    return (
        // <Link to={props.link}>
            <input
            type='image'
            src={image}
            className='customButton'
            onMouseEnter={() => handleImageChange(0)}
            onMouseLeave={() => handleImageChange(1)}
            onMouseDown={() => handleImageChange(2)}
            onMouseUp={() => handleImageChange(1)}
            onClick={() => click()}
        >
            
        </input>
        //</Link>
    );
};

export default CustomButton;
