import React from 'react';
import { useSpring, animated } from '@react-spring/web';

export type circleDivProps = {
  color: string
}

const CircleDiv: React.FC<circleDivProps> = ({color}) => {
    const styles = useSpring({
        loop: true,
        to: {scale: 3},
        from: {scale: 0},
        config: { duration: 5000 }
      });
    
      return (
        <animated.div
        className="centre"
          style={{
            width: 1000,
            height: 1000,
            border: `dotted 10px ${color}`,
            borderRadius: '50%',
            transform: 'translate(-50%, -50%)',
            ...styles, 
          }}
        />
    );
};

export default CircleDiv;