import { Unity, useUnityContext } from "react-unity-webgl";
import { useEffect, useState,Fragment, useRef } from "react";
import LiveChat from './LiveChat';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from "js-cookie";
import LoadingPage from "./LoadingPage/LoadingPage";
import { Call } from "@mui/icons-material";
import { PartiesUi } from "./PartiesUi";
import { ReactUnityEventParameter } from "react-unity-webgl/distribution/types/react-unity-event-parameters";
import { useAppSelector } from "../store/hooks";

type Props = {
  id:string | undefined
}

export interface RouteParams extends Record<string, string | undefined> {
  id: string;
}

export const GlobalRefs = {
  sendMessage: null as ReactUnityEventParameter | null,
};

export const UnityClient: React.FC<Props> = ({id}) => {

  const loader = "unityBuild/" + process.env.REACT_APP_UNITY_BUILD_FILE_LOADER
  const data = "unityBuild/" + process.env.REACT_APP_UNITY_BUILD_FILE_DATA
  const framework = "unityBuild/" + process.env.REACT_APP_UNITY_BUILD_FILE_FRAMEWORK
  const wasm = "unityBuild/" + process.env.REACT_APP_UNITY_BUILD_FILE_WASM

  const { unityProvider, isLoaded, loadingProgression, sendMessage, requestPointerLock } = useUnityContext({
        loaderUrl: loader,
        dataUrl:  data,
        frameworkUrl:  framework,
        codeUrl:  wasm      
  });

  const history = useNavigate();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const { avatarGlb } = useAppSelector(state => state.appUser)
  const [unityComponent, setUnityComponent] = useState<JSX.Element | null>(null);
  const [showUnityClient, setShowUnityClient] = useState(true);
  const [fullscreen, setFullscreen] = useState(false)

  useEffect(()=> {
    let cookie = Cookies.get("avatarUrl")
    let username = Cookies.get("username")
          if(isLoaded){
            let avatar = avatarGlb === "" ? cookie : avatarGlb
            if(id === "2914dced-44ad-4ed4-8d69-1e82af4e09bc"){
              Cookies.set("username", "Soledad")
              username = "Soledad"
              avatar = "https://avatars.nl-ams-1.linodeobjects.com/65edd09003a681e6e0feb4f4.glb"
            }
            sendMessage("ReactClientManager", "SetLocalAvatarDisplayName", username);
            sendMessage("ReactClientManager", "SetLocalAvatarUrl", avatar);
            sendMessage("ReactClientManager", "OnAvatarCreationCompleted");
          }    
    }, [avatarGlb, setShowUnityClient, isLoaded]);
    
  useEffect(() => {
    window.sendMessage = sendMessage
  });
      
  useEffect(() => {
    setUnityComponent(<Fragment><Unity className='unity'  unityProvider={unityProvider} ref={canvasRef}  tabIndex={1} /></Fragment>)
  }, [unityProvider]);
    

  async function handleclose() {
    history("/")
    window.location.reload() 
  }

  useEffect(() => {
    if(canvasRef.current !== null && isLoaded){
      canvasRef.current.addEventListener("click", async function () {
        if (typeof window.UNITY_CLIENT_WANTS_CURSOR_LOCK !== "undefined" && window.UNITY_CLIENT_WANTS_CURSOR_LOCK) {
          await requestPointerLock();
        }
      });
      var reportPointerLockState = function () {
	
        // tell the unity client the current cursor lock state
        sendMessage("ReactClientManager", "SetCurrentPointerLockState", typeof document.pointerLockElement === "undefined" || document.pointerLockElement === null ? 0 : 1);
        
        // if the cursor is currently shown, check if the unity client is reporting that it should be a hover cursor
        var isCursorShown = typeof window.UNITY_CLIENT_WANTS_CURSOR_LOCK === "undefined" || !window.UNITY_CLIENT_WANTS_CURSOR_LOCK;
        var wantsHoverCursor = isCursorShown && typeof window.UNITY_CLIENT_WANTS_HOVER_CURSOR !== "undefined" && window.UNITY_CLIENT_WANTS_HOVER_CURSOR;
        
        // apply the hover cursor or, as long as the cursor is currently shown rather than hidden, default it
        if (wantsHoverCursor) {
          if(canvasRef.current != null){
            canvasRef.current.style.cursor = "pointer";
          }  
          
        }
        else if (isCursorShown) {
          if(canvasRef.current != null){
            canvasRef.current.style.cursor = "default";
          }
        }
        
        // run again the next animation frame
        window.requestAnimationFrame(reportPointerLockState);
      };
      window.requestAnimationFrame(reportPointerLockState);
    }
    
  },[isLoaded])


    const toggleFullScreen = () => {
      if (!document.fullscreenElement) {
          document.documentElement.requestFullscreen().catch(err => {
            console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
          });
          setFullscreen(true)
        } else {
          if (document.exitFullscreen) {
            document.exitFullscreen();
            setFullscreen(false)
          }
        }
      };

      return(
       <Fragment>  {!isLoaded && showUnityClient && (
            <div className="loader">
              <LoadingPage />
            </div>
          )}{ showUnityClient && unityComponent} <div id="toFocus" className='controlBtns'> 
         
        </div>
        <div>
        {/* {
          id === "b2124128-a607-487f-b9de-33b03750cab9" ? <button style={{position: 'absolute', bottom: '10%'}} className="unityBtn"><Call /></button> : null
        } */}
        </div>
         <div><LiveChat/></div> : null
        {isLoaded && (
          <PartiesUi isHost={id === "b2124128-a607-487f-b9de-33b03750cab9" ? true : false}/>
        )}  
        
        </Fragment> 
      )
}