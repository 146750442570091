import React, { useState, useEffect } from "react";
import CircleDiv from "./CircleDiv";

type circleAnimProps = {
    colors: number[],
    interval: number
}
const CirclesAnim: React.FC<circleAnimProps> = ({ colors, interval }) => {
    const [circles, setCircles] = useState<number[]>([]);

    useEffect(() => {
        const timeoutIds:Array<any> = [];
        colors.forEach((color, index) => {
            const timeoutId = setTimeout(() => {
                setCircles(prevCircles => [...prevCircles, color]);
            }, index * interval);
            timeoutIds.push(timeoutId);
        });

        return () => {
            timeoutIds.forEach(timeoutId => clearTimeout(timeoutId));
        };
    }, [colors, interval]);

    return (
        <div className="centre">
            {circles.map((color, index) => (
                <CircleDiv key={index} color={'rgb(100,234,255)'} />
            ))}
        </div>
    );
};

export default CirclesAnim;